(function ($) {
  Drupal.behaviors.splitWidthProductContentBlockV2 = {
    attach: function (context) {
      var $template = $('.js-content-block-split-width-product', context);
      var $mediaLink = $('.js-content-block-split-width-product__media-link', $template);
      var $bellowMediaWrap = $('.js-content-block-split-width-product__bellow-media', $template);
      var $bellowMediaText = $('.js-content-block__line-truncate', $bellowMediaWrap);
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
      var windowWidth = $(window).width();
      var isMediumUp = windowWidth >= 1024;
      var charLimit = parseInt($bellowMediaText.attr('data-char-limit')) || 100;
      var commonMoreLabel = 'Learn More';
      var commonLessLabel = 'Learn Less';
      var labelMore = $bellowMediaText.attr('data-button-more') || commonMoreLabel;
      var labelLess = $bellowMediaText.attr('data-button-less') || commonLessLabel;

      $template.each(function () {
        var $thisTemplate = $(this);
        var $prodCardOverlay = $('.js-split-width-prod-card-overlay', $thisTemplate);
        var $prodCardWrapper = $('.js-split-width-product-card', $thisTemplate);
        var $prodCardCloseBtn = $(
          '.js-split-width-product-card-close, .js-content-block-product-close',
          $thisTemplate
        );
        var $ctaProdCard = $('.js-cta-prod-card .js-elc-button', $thisTemplate);
        var $contentFormatter = $thisTemplate.closest('.js-content-formatter-v1');
        var $productCard = $('.js-content-block-product-v3', $thisTemplate);
        var $html = $('html');

        $ctaProdCard.once().on('click', function (e) {
          var hasOpenProdCard = $thisTemplate.hasClass('product-cart-open');
          var $prdGridItem = $thisTemplate.find('.js-mpp-grid-item');
          e.preventDefault();
          $thisTemplate.toggleClass('product-cart-open', !hasOpenProdCard);
          $contentFormatter.toggleClass('product-cart-open', !hasOpenProdCard);
          $html.toggleClass('split-width-product-cart-open', !hasOpenProdCard);
          $prodCardWrapper.attr('aria-checked', !hasOpenProdCard);
          $prdGridItem.each(function () {
            $thisTemplate.find('.carousel-dots li.slick-active button').trigger('click');
          });
        });
        $prodCardOverlay.once().on('click', function (e) {
          e.preventDefault();
          closePrdCard();
        });
        $prodCardCloseBtn
          .once()
          .on('click', function (e) {
            e.preventDefault();
            closePrdCard();
          })
          .on('keydown', function (event) {
            if (event.key === 'Enter') {
              if (!$html.hasClass('split-width-product-cart-open')) {
                return;
              }
              closePrdCard();
            }
          });

        $(document).on('addToCart.success', function () {
          closePrdCard();
        });

        function closePrdCard() {
          $html.removeClass('split-width-product-cart-open');
          $thisTemplate.removeClass('product-cart-open');
          $contentFormatter.removeClass('product-cart-open');
          $prodCardWrapper.attr('aria-checked', false);
        }
        if (!isDesktop) {
          $productCard.filter('[data-dark-background="1"]').removeClass('dark-background');
        }

        Unison.on('change', function (bp) {
          var $productCardFilter = $productCard.filter('[data-dark-background="1"]');
          bp = Unison.fetch.now();
          isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
          $productCardFilter.toggleClass('dark-background', isDesktop);

          if (isDesktop) {
            closePrdCard();
          }
        });
      });
      if (isDesktop) {
        $template.each(function (value) {
          var $this = $(this);
          var $grid = $this.find('.js-content-block__product-grid');
          var splitBlockMediaHeight = 0;
          var splitBlockMediaHeight = $this
            .find('.js-content-block-split-width-product__media')
            .height();
          if ($grid.length > 0) {
            var $productBlock = $this.find('.js-content-block-product-v3');
            $productBlock.css('height', splitBlockMediaHeight);
          }
        });
      }
      $mediaLink.once().on('click', function (e) {
        $template.find('.carousel-dots li.slick-active button').trigger('click');
      });

      function truncateField(el) {
        var clone = el.children().first();
        var originalContent = el.html();
        var textFull = clone[0].innerHTML.trim();

        if (textFull.length > charLimit) {
          el.data('originalContent', originalContent);
          el.addClass('js-truncate-hidden');
          clone.html(textFull.substring(0, charLimit) + '...');
          el.empty().append(clone);
          el.append(
            $(
              '<div class="trigger-read-more js-trigger-read-more"><a href="#" class="show-content">' +
                labelMore +
                '</a><span class="show-content-sign"> +</span></div>'
            )
          );
        }
      }

      $bellowMediaWrap.on('click', 'a.show-content', function (e) {
        e.preventDefault();
        var truncateElement = $(this).parent().parent();

        if (truncateElement.hasClass('js-truncate-hidden')) {
          $(truncateElement).html(truncateElement.data('originalContent'));
          $(truncateElement).append(
            $(
              '<div class="trigger-read-more js-trigger-read-more"><a href="#" class="show-content">' +
                labelLess +
                '</a><span class="show-content-sign"> -</span></div>'
            )
          );
          truncateElement.removeClass('js-truncate-hidden');
        } else {
          $('.js-trigger-read-more', truncateElement).remove();
          truncateField(truncateElement);
        }
      });

      $bellowMediaText.each(function () {
        truncateField($(this));
      });
    }
  };
})(jQuery);
